import styled from "styled-components";

export const BlockTrailer = styled.div`
    position: relative;
    margin : -5px 0px 0px 0px;
    overflow : hidden;
  .blockTrailer__wrap {
    z-index: 2;
    position: relative;
    padding: 50px 100px;
  }
  .blockTrailer__wrap--content {
    padding: 50px 40px;
    position: relative;
  }
 .content__listTrailer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .listTrailer__item {
    border: 2px solid #50FB6B;
    border-radius: 20px;
    overflow: hidden;
  }
  .slick-slide {
    width: 100%;
    border: 2px solid #50FB6B;
    border-radius: 20px;
    height: auto;
    overflow: hidden;
    -webkit-transition: 0.3s cubic-bezier(0.65, 0.12, 0.26, 1);
    -o-transition: 0.3s cubic-bezier(0.65, 0.12, 0.26, 1);
    transition: 0.3s cubic-bezier(0.65, 0.12, 0.26, 1);
    position: relative;
  }
  .item__img {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    position: relative !important;
    z-index: 1;
  }
  .item__overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background : transparent;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
    -webkit-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    -o-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
  }
  .item__overlay--play {
    width: 60px;
    height: 60px;
    -webkit-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    -o-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    border-radius: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
  }
  .item__overlay--play:hover .play__img {
    transform : scale(1.1);
    filter: brightness(1.25);
    -webkit-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    -o-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
  }
  .play__img {
    width: 100%;
    height: auto;
    object-fit: cover;
    -webkit-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    -o-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
  }
  
  .slick-slider {
    position: relative;
    margin: auto;
  }
  /* BUTTON */
  .slick-next {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -35px;
  }
  .slick-next:before {
    background-image: url(/assets/images/icArrowNext.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    content: '';
    width: 70px;
    height: 70px;
    transition: 0.4s all;
  }
  .slick-prev {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -75px;
  }
  .slick-prev:before {
    background-image: url(/assets/images/icArrowPrev.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    content: '';
    width: 70px;
    height: 70px;
    transition: 0.4s all;
  }
`