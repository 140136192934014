// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'

import Image from 'next/image'
import React, { useRef, useState } from 'react'
// import Slider from 'react-slick'

import { Button } from '@pancakeswap/uikit/src/components/Button'
import { BlockTrailer } from './style'

// const settings = {
//   infinite: false,
//   arrows: false,
//   slidesToShow: 1,
//   draggable: true,
// }

const Trailer = () => {
  const [statusVideo, setStatusVideo] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handleOpenVideo = () => {
    setStatusVideo(!statusVideo)
    if (videoRef.current) {
      if (statusVideo) {
        videoRef.current.pause()
        videoRef.current.currentTime = 0
      } else {
        videoRef.current.play()
      }
    }
  }

  return (
    <>
      <BlockTrailer
        className="blockTrailer"
        id="trailer"
        style={{
          backgroundImage: `url("/images/bgTrailerGroot.png")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top',
          backgroundSize: 'cover',
        }}
      >
        <div className="blockTrailer__wrap blockContainer">
          <div className="blockTrailer__wrap--content" data-aos="fade-up" data-aos-duration="800">
            <div className="listTrailer__item">
              <Image className="item__img" src="/images/bgTrailer1.webp" alt="" width="0" height="0" sizes="100vw" />
              <Button className="item__overlay" onClick={handleOpenVideo}>
                <div className="item__overlay--play">
                  <Image className="play__img" src="/images/play.png" alt="Play" width={30} height={30} />
                </div>
              </Button>
            </div>
          </div>
        </div>
      </BlockTrailer>
      <div className={`blockTrailer__modal ${statusVideo ? 'show' : ''}`}>
        <div className="blockTrailer__modal--tool">
          <Button className="tool__item" onClick={handleOpenVideo}>
            <Image
              className="tool__item--img"
              src="/images/close.svg"
              alt="Close"
              width={30}
              height={30}
              sizes="100vw"
            />
          </Button>
        </div>
        <video
          ref={videoRef}
          controls
          preload='none'
          className="blockTrailer__modal--video"
        >
          <source src="/videos/1.mp4" type="video/mp4" />
        </video>
      </div>
    </>
  )
}
export default Trailer
