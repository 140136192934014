import styled, { keyframes } from 'styled-components'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

const fadeInOut = keyframes`
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-100%, 0);
  }
`

const BlockHero = styled.div`
  position: relative;
`

const BlockHeroWrap = styled.div`
  position: relative;
`

const BackgroundImg = styled(Image)`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  @media screen and (max-width: 1199px) {
    height: 90vh;
  }
`

const BlockHeroWrapContent = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 1199px) {
    height: 82.5vh;
  }
`

const BlockBuyToken = styled.div`
  background: rgba(25, 56, 39, 0.6);
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.6);
  position: absolute;
  left: 0;
  cursor: pointer;
  right: 0;
  margin: 0 auto;
  bottom: 30px;
  width: 310px;
  border-radius: 14px;
  height: auto;
  z-index: 2;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1199px) {
    bottom: 10px;
  }
  .blockBuyToken__buyContent {
    width: 100%;
    margin: 0px 0px 0px 5px;
  }
  .blockBuyToken__buyContent--audit {
    display: flex;
    width: 100%;
    padding: 0px 5px;
    align-items: center;
    justify-content: center;
  }
  .audit__item {
    display: flex;
    margin: 0px 5px;
  }
  .audit__item--img {
    width: 14px;
    height: 14px;
    object-fit: contain;
    margin: 0px 5px 0px 0px;
  }
  .audit__item--text {
    font-size: 1rem;
    line-height: calc(1rem * 1.25);
    font-family: SUISSE_BOLD;
  }
  .blockBuyToken__buyContent--text {
    font-size: 2rem;
    line-height: calc(2rem * 1.25);
    margin: 0px 0px 10px 0px;
    text-align: center;
    font-family: SUISSE_BLACK;
    display: flex;
    align-items: center;

    flex-direction: column;
    p {
      width: fit-content;
      margin: 2.5px 0px 5px 0px;
      text-align: center;
      padding: 2px 7.5px 2.5px 7.5px;
      border-radius: 10px;
      font-size: 1.25rem;
      line-height: calc(1.25rem * 1.25);
      color: #fff;
      display: flex;
      align-items: center;
      background: #f95192;
      font-family: SUISSE_BLACK;
    }
  }
  .blockBuyToken__img {
    width: 50px;
    height: 50px;
  }
`

const BlockBuyTokenImg = styled(Image)`
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin: 0px 10px 0px 0px;
`

const ImgCart = styled(Image)`
  width: 12px;
  height: 12px;
  filter: invert(1);
  margin: 0px 7.5px 0px 0px;
`

const Hero = () => {
  return (
    <BlockHero className="blockHero" id="home">
      <BlockHeroWrap className="blockHero__wrap">
        <BackgroundImg
          className="background__img"
          alt=""
          src="/images/bgHeroGroot.png"
          width="0"
          height="0"
          sizes="100vw"
        />
        <BlockHeroWrapContent className="blockHero__wrap--content blockContainer">
          <BlockBuyToken className="blockBuyToken">
            <BlockBuyTokenImg
              className="blockBuyToken__img"
              src="/logo.png"
              width={0}
              height={0}
              sizes="100vw"
              alt=""
            />
            <div className="blockBuyToken__buyContent">
              <Link
                className="blockBuyToken__buyContent--text"
                target="_blank"
                href="https://www.pinksale.finance/launchpad/0xbd5d712B17675A35dBafF481B5366b845F33A7a7?chain=BSC"
              >
                BUY XGROOT{' '}
                <p>
                  <ImgCart src="/images/icCart.svg" width={0} height={0} sizes="100vw" alt="" />
                  on PinkSale
                </p>
              </Link>

              <div className="blockBuyToken__buyContent--audit">
                <Link
                  href="https://github.com/Coinsult/solidity/blob/main/Coinsult_XGroot_0x9b...c6fc_Audit.pdf"
                  className="audit__item"
                  target="_blank"
                >
                  <Image
                    className="audit__item--img"
                    src="/images/pinksale.png"
                    alt=""
                    width={0}
                    height={0}
                    sizes="100vw"
                  />
                  <div className="audit__item--text">AUDIT PinkSale</div>
                </Link>
                <Link
                  href="https://pinksale.notion.site/XGroot-KYC-Verification-76c0c708317f4e49a5b0bd1d0e4849fa?pvs=4"
                  className="audit__item"
                  target="_blank"
                >
                  <Image
                    className="audit__item--img"
                    src="/images/pinksale.png"
                    alt=""
                    width={0}
                    height={0}
                    sizes="100vw"
                  />
                  <div className="audit__item--text">KYC PinkSale</div>
                </Link>
              </div>
            </div>
          </BlockBuyToken>
        </BlockHeroWrapContent>
      </BlockHeroWrap>
    </BlockHero>
  )
}

export default Hero
