import React from 'react'

import {
  BlockRoadmap,
  ContentRoadmap,
  ContentWrap,
  DescRoadmap,
  ImgBackground,
  InfoRoadmap,
  ItemRoadmap,
  ListRoadmap,
  TextTitle,
  TitleRoadmap,
} from './style'

const dataRoadmap = [
  {
    title: 'PHASE 1 : Initial Launch and Market Penetration (Q1 - Q2 2024)',
    desc: '- Finalize and deploy the blockchain platform with NFT and carbon credit functionalities.<br />- Begin marketing campaigns to build brand awareness.<br />- Establish initial partnerships with environmental organizations and influencers in the blockchain space.',
    content: `Successfully launch "XGroot" and establish a foothold in the market.`,
    active: 1,
  },
  {
    title: 'PHASE 2 : User Base Growth and Platform Optimization (Q3 - Q4 2024)',
    desc: `- Enhance user engagement through targeted marketing and community-building initiatives.<br />- Collect user feedback and implement necessary improvements and features.<br />- Monitor and optimize the platform's performance for scalability and user experience.`,
    content: 'Expand the user base and refine the platform based on early user feedback.',
    active: 0,
  },
  {
    title: 'PHASE 3 : Strategic Partnerships and Expansion (Q4 2024)',
    desc: '- Collaborate with additional environmental agencies and corporate entities for broader reach and impact.<br />- Conduct market research to identify potential regions for expansion.<br />- Develop a roadmap for integrating additional features or services based on market trends and user demands.',
    content: 'Develop strategic partnerships and begin planning for geographical expansion.',
    active: 0,
  },
]

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1199,

      settings: {
        slidesToShow: 2,
        dots: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        dots: true,
      },
    },
  ],
}

const Roadmap = () => {
  return (
    <BlockRoadmap id="roadmap">
      <ContentWrap className="blockContainer">
        <TextTitle>Roadmap</TextTitle>
        <ListRoadmap {...settings}>
          {dataRoadmap.map((item) => {
            return (
              <ItemRoadmap className={`${item.active === 1 && 'active'}`}>
                {item.active === 1 ? <ImgBackground src="/images/bgRoadmapActive.gif" alt="" /> : null}
                <ContentRoadmap>
                  <TitleRoadmap className="titleRoadmap">{item.title}</TitleRoadmap>
                  <DescRoadmap dangerouslySetInnerHTML={{ __html: item.desc }} />
                  <InfoRoadmap>{item.content}</InfoRoadmap>
                </ContentRoadmap>
              </ItemRoadmap>
            )
          })}
        </ListRoadmap>
      </ContentWrap>
    </BlockRoadmap>
  )
}
export default Roadmap
