import styled from "styled-components"

export const BlockBurnToken = styled.div`
    padding: 75px 50px;
    position : relative;
    z-index : 2;
    background  : #000;
    @media screen and (max-width: 1199px) {
        padding: 50px 0px;
    }
`

export const ContentWrap = styled.div``
export const TextTitle = styled.div`
  margin : 40px 0px 60px 0px;
  font-size : 2.8rem;
  line-height: calc(2.8rem * 1.25);
  font-family : SUISSE_BOLD;
  color : #FFF;
  text-align: center;
  text-transform : uppercase;
  @media screen and (max-width: 1199px) {
      font-size : 2rem;
      line-height: calc(2rem * 1.25);
  }
`


export const WrapBurnToken = styled.div`
  width: 100%;
  max-width: 450px;
  margin : auto;
  background: #f12711; 
  background: -webkit-linear-gradient(to right, #f5af19, #f12711); 
  background: linear-gradient(to right, #f5af19, #f12711);
  padding: 20px;
  display : flex;
  align-items : center;
  flex-direction : column;
  border-radius : 10px;
  position : relative;

`
export const TitleBurn = styled.div`
    font-family : SUISSE_BOLD;
    font-size : 2rem;
    line-height: calc(2rem * 1.25);
    text-transform : uppercase;
`
export const DescBurn = styled.div`
  margin : 5px 0px 30px 0px;
  font-size : 1.25rem;
  line-height: calc(1.25rem * 1.25);
`
export const AddressBurn = styled.div`
    font-family : SUISSE_BOLD;
    @media screen and (max-width : 767px) {
        font-size : 1.25rem;
        line-height: calc(1.25rem * 1.25);
    }
`

export const ImgBurn1 = styled.img`
    width : 80px;
    height: 80px;
    object-fit : contain;
    position : absolute;
    top : -25px;
    left : -20px;
    transform : rotate(5deg);
`
export const ImgBurn2 = styled.img`
    width : 80px;
    height: 150px;
    object-fit : contain;
    position : absolute;
    bottom : 0px;
    right : -20px;
    object-fit : contain;
`