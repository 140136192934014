import styled from "styled-components"
import Slider from "react-slick";

export const BlockReview = styled.div`
    padding: 50px 50px 75px 50px;
    position : relative;
    z-index : 2;
    background  : #000;
    @media screen and (max-width: 767px) {
        padding: 50px 15px;
    }
`

export const ContentWrap = styled.div``
export const WrapVideoReview = styled.div`
    width: 100%;
    display : flex;
    justify-content : center;
    iframe {
        border-radius : 10px;
        width : 1032px;
        height: 630px;
        @media screen and (max-width: 1199px) {
            width : 688px;
            height: 420px;
        }
        @media screen and (max-width: 991px) {
            width: 516px;
            height: 315px;
        }
        @media screen and (max-width: 767px) {
            width: 344px;
            height: 210px;
        }
        @media screen and (max-width: 380px) {
            width: 295px;
            height: 180px;
        }
    }
`

export const WrapTitle = styled.div`
  margin : 0px 0px 25px 0px;
  font-size : 2.4rem;
  line-height: calc(2.4rem * 1.25);
  text-align : center;
  font-family : SUISSE_BOLD;
  span {
    color :  #50FB6B;
  }
  a {
    color : rgba(242,190,71);
  }
  @media screen and (max-width: 767px) {
    font-size : 1.6rem;
    line-height: calc(1.6rem * 1.25);
}
`

export const ItemReview = styled.div`
    margin : 75px 0px 0px 0px;
`

export const ListReview = styled(Slider)`
    .slick-slide {
        padding: 0px 10px;
    }
    .slick-next,
    .slick-prev {
        width : 50px;
        height: 50px;
        background: #FFF; 
        border-radius : 50%;
        z-index : 2;
        display : flex;
        align-items : center;
        top : calc(50% - 50px);
    }
    .slick-prev:before {
        transform : rotate(-180deg);
        left : calc(50% - 10px);
    }
    .slick-next:before {
        left : calc(50% - 8px);
    }
    @media screen and (max-width: 767px) {
        .slick-next {
            right: -20px;
            top : calc(100% - 12.5px);
        }
        .slick-prev {
            left : -20px;
            top : calc(100% - 12.5px);
        }
    }
    .slick-prev:before, 
    .slick-next:before {
        content : "";
        background-image : url(../images/icArrow.svg);
        background-size : cover;
        background-position : center;
        background-repeat : no-repeat;
        width : 18px !important;
        height: 18px !important;
        display : flex;
        top : calc(50% - 24.5px);
        position : relative;
        transition : 0.6s cubic-bezier(.62,-0.86,.56,1.51);
    }
    .slick-arrow {
        transition : 0.6s cubic-bezier(.62,-0.86,.56,1.51);
        transform : scale(1);
        &:hover {
            filter : brightness(0.8);
            transition : 0.6s cubic-bezier(.62,-0.86,.56,1.51);
        }
    }
    .slick-arrow.slick-disabled {
        transform : scale(0);
        transition : 0.6s cubic-bezier(.62,-0.86,.56,1.51);
    }
    .slick-dots li button:before {
      color : #FFF;
    }
`

export const TextTitle = styled.div`
  margin : 40px 0px 20px 0px;
  font-size : 2.8rem;
  line-height: calc(2.8rem * 1.25);
  font-family : SUISSE_BOLD;
  color : #FFF;
  text-align: center;
  text-transform : uppercase;
  @media screen and (max-width: 1199px) {
      font-size : 2rem;
      line-height: calc(2rem * 1.25);
  }
`