import dynamic from 'next/dynamic'
import React, { useState } from 'react'

import { BlockLogo, ImgLogo, WrapChart } from '../style'

const ReactApexChart = dynamic(() => import('react-apexcharts'), { ssr: false })

const ChartContainer = () => {
  const [chartData] = useState({
    series: [50, 10, 10, 20, 5, 5],
    options: {
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: true,
        minAngleToShowLabel: 1,
        formatter(val, opt) {
          return `${opt.w.globals.labels[opt.seriesIndex]}: ${val}%`
        },
        background: {
          enabled: true,
          foreColor: '#FFF',
          borderRadius: 2,
          padding: 2,
          borderWidth: 0,
          borderColor: '#000',
        },
        fill: '#000',
        textAnchor: 'start',
        dropShadow: {
          enabled: false,
        },
        style: {
          fontSize: '10px',
          fontFamily: 'SUISSE_BOLD',
          colors: ['#000', '#000'],
        },
      },
      chart: {
        foreColor: '#ffffff',
        fontFamily: 'SUISSE_BOLD',
      },
      legend: {
        show: false,
      },
      labels: ['Burn', 'Presale', 'Liquidity', 'Development', 'Marketing', 'F&D'],
      stroke: {
        width: 2,
      },
      responsive: [
        {
          breakpoint: 767,
          options: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '8px',
                fontFamily: 'SUISSE_BOLD',
              },
            },
            chart: {
              width: 320,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      fill: {
        colors: ['#a7c957', '#52b788', '#008000', '#006400', '#70e000', '#55a630'],
      },
      plotOptions: {
        pie: {
          dataLabels: {
            minAngleToShowLabel: 0,
          },
        },
      },
    },
  }) as any

  return (
    <WrapChart>
      <ReactApexChart options={chartData.options} series={chartData.series} type="pie" width={425} />
      <BlockLogo>
        <ImgLogo src="/logo.png" />
      </BlockLogo>
    </WrapChart>
  )
}

export default ChartContainer
