import Link from "next/link";
import Slider from "react-slick";
import styled from "styled-components";

export const BlockNews = styled.div`
    padding: 75px 50px;
    position : relative;
    z-index : 2;
    background  : #000;
    @media screen and (max-width: 1199px) {
        padding: 50px 0px;
    }
`

export const ContentWrap = styled.div``
export const ListNews = styled(Slider)`
    .slick-slide {
        padding: 0px 10px;
    }
    .slick-next,
    .slick-prev {
        width : 50px;
        height: 50px;
        background: #FFF; 
        border-radius : 50%;
        z-index : 2;
        display : flex;
        align-items : center;
        top : calc(50% - 50px);
    }
    .slick-prev:before {
        transform : rotate(-180deg);
        left : calc(50% - 10px);
    }
    .slick-next:before {
        left : calc(50% - 8px);
    }
    @media screen and (max-width: 1199px) {
        .slick-next {
            right: -10px;
        }
        .slick-prev {
            left : -10px;
        }
    }
    .slick-prev:before, 
    .slick-next:before {
        content : "";
        background-image : url(../images/icArrow.svg);
        background-size : cover;
        background-position : center;
        background-repeat : no-repeat;
        width : 18px !important;
        height: 18px !important;
        display : flex;
       
        top : calc(50% - 24.5px);
        position : relative;
        transition : 0.6s cubic-bezier(.62,-0.86,.56,1.51);
    }
    .slick-arrow {
        transition : 0.6s cubic-bezier(.62,-0.86,.56,1.51);
        transform : scale(1);
        &:hover {
            filter : brightness(0.8);
            transition : 0.6s cubic-bezier(.62,-0.86,.56,1.51);
        }
    }
    .slick-arrow.slick-disabled {
        transform : scale(0);
        transition : 0.6s cubic-bezier(.62,-0.86,.56,1.51);
    }
    .slick-dots li button:before {
      color : #FFF;
    }
`
export const ItemNews = styled(Link)`
    height : 400px;
    position : relative;
    display : flex;
    transition : 0.4s all;
    border-radius : 10px;
    overflow : hidden;
    &:hover {
        filter : brightness(0.8);
        transition : 0.4s all;
    }
    @media screen and (max-width : 1199px) {
      height: 450px;
    }
    @media screen and (max-width : 767px) {
      height: 400px;
    }
`
export const ImgNews = styled.img`
    width : calc(100% - 10px);
    margin : 5px;
    height: 250px;
    min-height: 250px;
    object-fit : cover;
    border : 2px solid #50FB6B;
    border-radius : 20px;
`
export const ContentNews = styled.div`
    position : relative;
    z-index : 2;
    display : flex;
    flex-direction : column;
    width : 100%;
    height: 100%;
    padding: 20px;
`
export const TitleNews = styled.div`
    font-size : 2rem;
    line-height: calc(2rem * 1.25);
    font-family : SUISSE_BOLD;
    color : #FFF;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`
export const DescNews = styled.div`
    margin : 10px 0px 0px 0px;
    color : rgba(200,200,200);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`


export const WrapViewMore = styled.div`
    margin : 75px auto 0px auto;
    display : flex;
    align-items : center;
    justify-content : center;
    button {
        height: 35px;
        padding : 0px 15px;
        border-radius : 5px;
    }
`

export const TextTitle = styled.div`
  margin : 40px 0px 60px 0px;
  font-size : 2.8rem;
  line-height: calc(2.8rem * 1.25);
  font-family : SUISSE_BOLD;
  color : #FFF;
  text-align: center;
  text-transform : uppercase;
  @media screen and (max-width: 1199px) {
      font-size : 2rem;
      line-height: calc(2rem * 1.25);
  }
`