import styled from "styled-components";

export const BlockAirdrop = styled.div`
    background  : #000;
    padding: 75px 0px;
    margin : -5px 0px 0px 0px;
`

export const ContentWrap = styled.div`
    display : flex;
    flex-direction : column;
    align-items : center;
`
export const InfoAirdrop = styled.div`
    margin : 0px 0px 35px 0px;
`
export const TitleAirdrop = styled.div`
    font-family : SUISSE_BOLD;
    text-transform : uppercase;
    font-size: 2rem;
    line-height : calc(2rem * 1.25);
    text-align: center;
`
export const DescAirdrop = styled.div`
    text-align: center;
    margin : 15px 0px 0px 0px;
    font-family : SUISSE_BOLD;
    font-size: 1.6rem;
    line-height : calc(1.6rem * 1.25);
    p {
        margin : 0px 0px 5px 0px;
    }
`
export const BannerAirdrop = styled.img`
    width: 100%;
    max-width: 735px;
    border-radius : 10px;
    margin : 0 auto;
`

export const WrapMint = styled.div`
    margin : 20px 0px 0px 0px;
`