import styled from 'styled-components'
import Image from 'next/image'
import React from 'react'
import Link from 'next/link'

const BlockPartner = styled.div`
  position: relative;
  margin: 0px 0px -5px 0px;
`

const BlockPartnerWrap = styled.div`
  position: relative;
  min-height: 200px;
  padding: 20px 0px;
`

const BackgroundImg = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  transform: rotate(180deg);
`

const BlockPartnerWrapContent = styled.div`
  position: relative;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 550px !important;
  flex-wrap: wrap;
  margin: 0 auto !important;
  padding: 70px 15px !important;
`

const ItemPartner = styled(Link)`
  width: calc(100% / 2 - 10px);
  height: 100px;
  margin: 4.9px;
  object-fit: contain;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px 20px;
  border-radius: 10px;
`

const ImgPartner = styled.img`
  width: 100%;
  height: 50px;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
`

const Partner = () => {
  return (
    <BlockPartner className="blockPartner" id="home">
      <BackgroundImg
        className="background__img"
        alt=""
        src="/images/bgTrailerGroot.png"
        width="0"
        height="0"
        sizes="100vw"
      />
      <BlockPartnerWrap className="blockPartner__wrap">
        <BlockPartnerWrapContent className="blockContainer">
          <ItemPartner href="https://www.dexview.com/" target="_blank">
            <ImgPartner src="/images/partner/1.png" />
          </ItemPartner>
          <ItemPartner href="https://www.okx.com/" target="_blank">
            <ImgPartner src="/images/partner/2.png" />
          </ItemPartner>
          <ItemPartner href="https://www.pinksale.finance/" target="_blank">
            <ImgPartner src="/images/partner/3.png" />
          </ItemPartner>
          <ItemPartner href="https://coinmarketcap.com/" target="_blank">
            <ImgPartner src="/images/partner/4.png" />
          </ItemPartner>
        </BlockPartnerWrapContent>
      </BlockPartnerWrap>
    </BlockPartner>
  )
}

export default Partner
