import React from 'react'

import {
  AddressBurn,
  BlockBurnToken,
  ContentWrap,
  DescBurn,
  ImgBurn1,
  ImgBurn2,
  TextTitle,
  TitleBurn,
  WrapBurnToken,
} from './style'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const BurnToken = () => {
  return (
    <BlockBurnToken id="BurnToken">
      <ContentWrap className="blockContainer">
        <TextTitle>Burn Token</TextTitle>
        <WrapBurnToken>
          <TitleBurn>Contract Burn Token</TitleBurn>
          <DescBurn>(*) Burned tokens cannot be traded</DescBurn>
          <AddressBurn>0xE4DFFFa03232F42F888214f5618476314C883948</AddressBurn>
          <ImgBurn1 src="/images/burn1.webp" />
          <ImgBurn2 src="/images/burn2.webp" />
        </WrapBurnToken>
      </ContentWrap>
    </BlockBurnToken>
  )
}
export default BurnToken
