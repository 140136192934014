import React from 'react'
import { Button } from '@pancakeswap/uikit'
import Link from 'next/link'
import { BannerAirdrop, BlockAirdrop, ContentWrap, DescAirdrop, InfoAirdrop, TitleAirdrop, WrapMint } from './style'

const Airdrop = () => {
  return (
    <BlockAirdrop id="airdrop">
      <ContentWrap className="blockContainer">
        <InfoAirdrop>
          <TitleAirdrop>🔥🔥🔥 X-Groot 1st and biggest airdrop is on the way! 🔥🔥🔥</TitleAirdrop>
          <DescAirdrop>
            <p>💰 The total campaign goes up to $1,000,000. </p>
            <p>🤑 1/100,000 worth $10 XGroot NFTs can be yours!</p>
            <p>👉 Follow us for later updates!</p>
            <WrapMint>
              <Link href="/mint-token">
                <Button>Mint Free Now!</Button>
              </Link>
            </WrapMint>
          </DescAirdrop>
        </InfoAirdrop>
        <BannerAirdrop src="/images/bannerAirdrop.jpeg" />
      </ContentWrap>
    </BlockAirdrop>
  )
}

export default Airdrop
