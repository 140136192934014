import React from 'react'

import Link from 'next/link'
import { BlockReview, ContentWrap, ItemReview, ListReview, TextTitle, WrapTitle, WrapVideoReview } from './style'

const News = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <BlockReview>
      <ContentWrap className="blockContainer">
        <TextTitle>Review</TextTitle>
        <ListReview {...settings}>
          <ItemReview>
            <WrapTitle>
              <span>X-GROOT</span> review from channel
              <Link href="https://www.youtube.com/@CryptoMastermindeu" target="_blank">
                {' '}
                CryptoMastermind
              </Link>
            </WrapTitle>
            <WrapVideoReview>
              <iframe
                src="https://www.youtube.com/embed/pbz5OUScpcc?si=tbhBZr407FzydhW1"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </WrapVideoReview>
          </ItemReview>
          {/* <ItemReview>
            <WrapTitle>
              <span>X-GROOT</span> review from channel
              <Link href="https://www.youtube.com/@WolfPromoterq" target="_blank">
                {' '}
                Crypto Wolf
              </Link>
            </WrapTitle>
            <WrapVideoReview>
              <iframe
                src="https://www.youtube.com/embed/nvxjJ3CrM4g?si=WGGZ6m06-erb8t8n"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </WrapVideoReview>
          </ItemReview> */}
          <ItemReview>
            <WrapTitle>
              <span>X-GROOT</span> review from channel
              <Link href="https://www.youtube.com/@cryptoameer4" target="_blank">
                {' '}
                CRYPTO AMEER
              </Link>
            </WrapTitle>
            <WrapVideoReview>
              <iframe
                src="https://www.youtube.com/embed/HRJsWIFT8L0?si=MmoA63M0ETlOWwxX"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </WrapVideoReview>
          </ItemReview>
          {/* <ItemReview>
            <WrapTitle>
              <span>X-GROOT</span> review from channel
              <Link href="https://www.youtube.com/@nftroshan4335" target="_blank">
                {' '}
                NFT ROSHAN
              </Link>
            </WrapTitle>
            <WrapVideoReview>
              <iframe
                src="https://www.youtube.com/embed/oDdQ0pTvCMM?si=gA1ZqHo3qjAkp1P"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </WrapVideoReview>
          </ItemReview> */}
          <ItemReview>
            <WrapTitle>
              <span>X-GROOT</span> review from channel
              <Link href="https://www.youtube.com/@ReviewNFTgaming-qd2sc" target="_blank">
                {' '}
                Review NFT gaming
              </Link>
            </WrapTitle>
            <WrapVideoReview>
              <iframe
                src="https://www.youtube.com/embed/elZcdQJYW6A?si=lnsCt9IKmdcVWA2P"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </WrapVideoReview>
          </ItemReview>
          <ItemReview>
            <WrapTitle>
              <span>X-GROOT</span> review from channel
              <Link href="https://www.youtube.com/@Futureofcrypto7866" target="_blank">
                {' '}
                FUTURES OF CRYPTO
              </Link>
            </WrapTitle>
            <WrapVideoReview>
              <iframe
                src="https://www.youtube.com/embed/C-8p39Okl4U?si=Liyep74nEyBH7F-4"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </WrapVideoReview>
          </ItemReview>
          <ItemReview>
            <WrapTitle>
              <span>X-GROOT</span> review from channel
              <Link href="https://www.youtube.com/channel/UClaWFyot1tImPUpGzT94c1A" target="_blank">
                {' '}
                CRPYTO FIRE 🔥
              </Link>
            </WrapTitle>
            <WrapVideoReview>
              <iframe
                src="https://www.youtube.com/embed/PmjmCCxwpzI?si=7LdGvTk9lHsfqy0W"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </WrapVideoReview>
          </ItemReview>
          <ItemReview>
            <WrapTitle>
              <span>X-GROOT</span> review from channel
              <Link href="https://www.youtube.com/@nftmoon34" target="_blank">
                {' '}
                NFT MOON
              </Link>
            </WrapTitle>
            <WrapVideoReview>
              <iframe
                src="https://www.youtube.com/embed/ztJRD7MEPSo?si=aMI2iAJK9PhgxTxf"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </WrapVideoReview>
          </ItemReview>
        </ListReview>
      </ContentWrap>
    </BlockReview>
  )
}
export default News
