import Image from 'next/image'
import Link from 'next/link'
import * as React from 'react'
import Countdown, { zeroPad } from 'react-countdown'

// import type { SettingType } from '@/common/types';

export type SettingType = { [key: string]: string }

// interface PresaleProps {
//   systems?: SettingType

// }

const Presale = () => {
  const timeEnd = new Date('2024-02-24T20:00:00').getTime()
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Sale has ended!!</span>
    }

    return (
      <div className="listTime__wrap--listItem">
        <div className="listItem__item">{days}d</div>
        <div className="listItem__item">{zeroPad(hours)}h</div>
        <div className="listItem__item">{zeroPad(minutes)}m</div>
        <div className="listItem__item">{zeroPad(seconds)}s</div>
      </div>
    )
  }

  return (
    <div className="blockPresale">
      <div className="blockPresale__wrap blockContainer">
        <div className="blockPresale__wrap--content">
          <div className="content__brand">
            <p className="content__brand--text">
              Buy on <span>PinkSale</span>
            </p>
          </div>
          <div className="content__countdown">
            <div
              className="content__countdown--text"
              //   dangerouslySetInnerHTML={{ __html: systems?.pinksaleDesc ?? '' }}
            >
              {`The first sale of the Ordinal XGROOT 'XGT'. Token is taking place on the Pinksale Launchpad. The sale starts on February 19th at 12:00 PM UTC and lasts for 5 days.`}
            </div>
            <Countdown date={timeEnd} renderer={renderer} />
          </div>
          <div className="content__buy">
            <Link
              href="https://www.pinksale.finance/launchpad/0xbd5d712B17675A35dBafF481B5366b845F33A7a7?chain=BSC"
              className="buy__content--btnBuy"
              target="_blank"
            >
              <Image src="/images/icCart.svg" alt="" className="btnBuy__img" width={0} height={0} sizes="100vw" />
              <p className="btnBuy__text">Buy on PinkSale</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Presale
