import Slider from "react-slick"
import styled from "styled-components"

export const BlockRoadmap = styled.div`
    padding : 75px 0px;
    background : #000;
`
export const ContentWrap = styled.div`
 
`

export const ListRoadmap = styled(Slider)`
    margin-top : 80px;
    @media screen and (max-width: 1199px) {
        .slick-next {
            right: -10px;
            bottom : 0;
            top : unset !important;
        }
        .slick-prev {
            left : -10px;
            bottom : 0;
            top : unset !important;
        }
    }
    .slick-slide {
      padding: 0px 10px;
      cursor : pointer;
      .titleRoadmap {
        color : #50FB6B;
      }
    }
    .slick-next,
    .slick-prev {
        width : 50px;
        height: 50px;
        background: #FFF; 
        border-radius : 50%;
        z-index : 2;
        display : flex;
        align-items : center;
        top : calc(50% - 35px);
    }
    .slick-prev:before {
        transform : rotate(-180deg);
        left : calc(50% - 10px);
    }
    .slick-next:before {
        left : calc(50% - 8px);
    }
    .slick-prev:before, 
    .slick-next:before {
        content : "";
        background-image : url(/images/icArrow.svg);
        background-size : cover;
        background-position : center;
        background-repeat : no-repeat;
        width : 18px !important;
        height: 18px !important;
        display : flex;
        top : calc(50% - 24.5px);
        position : relative;
        transition : 0.6s cubic-bezier(.62,-0.86,.56,1.51);
    }
    .slick-arrow {
        transform : scale(1);
        transition : 0.6s cubic-bezier(.62,-0.86,.56,1.51);
        &:hover {
            filter : brightness(0.8);
            transition : 0.6s cubic-bezier(.62,-0.86,.56,1.51);
        }
    }
    .slick-arrow.slick-disabled {
        transform : scale(0);
        transition : 0.6s cubic-bezier(.62,-0.86,.56,1.51);
    }
    .slick-dots li button:before {
      color : #FFF;
    }
`
export const ItemRoadmap = styled.div`
    height : 375px;
    background : #000;
    padding: 0px 0px 0px 0px;
    position : relative;
    display : flex;
    border-radius : 24px;
    transition : 0.4s all;
    filter : brightness(0.8);
    @media screen and (max-width : 1199px) {
      filter : brightness(1);
    }
    &.active {
        filter : brightness(1.2);
    }
    &:hover {
        filter : brightness(1);
        transition : 0.4s all;
    }
    overflow : hidden;
    border : 2px solid #50FB6B;
`
export const ImgRoadmap = styled.img`
    width : 125px;
    height: auto;
    object-fit : contain;
    position : absolute;
    bottom : -20px;
    right : -25px;
    z-index : 4;
    
`
export const ContentRoadmap = styled.div`
    position : relative;
    z-index : 3;
    display : flex;
    flex-direction : column;
    justify-content : flex-start;
    width : 100%;
    height: 100%;
    padding: 25px;
    background : rgba(0,0,0,0.6);
    backdrop-filter : blur(10px);
    -webkit-backdrop-filter : blur(10px);
`
export const TitleRoadmap = styled.div`
    font-size : 2rem;
    line-height: calc(2rem * 1.25);
    min-height: 80px;
    font-family : SUISSE_BOLD;
    margin : 0px 0px 10px 0px;
`
export const DescRoadmap = styled.div`
    color : #FFF;
    display: -webkit-box;
    font-size : 1.3rem;
    line-height: calc(1.3rem * 1.25);
`
export const InfoRoadmap = styled.div`
    color : #50FB6B;
    margin : 20px 0px 0px 0px;
    font-family : SUISSE_BOLD;
    text-transform : uppercase;
`

export const ImgBackground = styled.img`
    width : 100%;
    height: 100%;
    position : absolute;
    top : 0;
    left : 0;
    z-index: 0;
    object-fit : cover;
`

export const TextTitle = styled.div`
    margin : 40px 0px 20px 0px;
    font-size : 2.8rem;
    line-height: calc(2.8rem * 1.25);
    font-family : SUISSE_BOLD;
    color : #FFF;
    text-align: center;
    text-transform : uppercase;
    @media screen and (max-width: 1199px) {
        font-size : 2rem;
        line-height: calc(2rem * 1.25);
    }
`