import styled from "styled-components";

export const BlockFooter = styled.div`
    padding: 30px 0px 0px 0px;
    background: rgba(39,68,33);
    position: relative;
    z-index: 0;
    @media screen and (max-width : 991px) {
      padding: 150px 0px 0px 0px;
    }
  .blockFooter__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width : 991px) {
      flex-direction : column;
    }
  }
 .info__social--listItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
 .listItem__item {
    width: 50px;
    height: 50px;
    margin: 0px 5px 0px 0px;
    background: rgb(39, 39, 39);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
  }
 .listItem__item::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    content: "";
    background: #000;
    border-radius: 50%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    z-index: 1;
  }
 .listItem__item:hover::before {
    height: 100%;
    width: 100%;
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    border-radius: 50%;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
 .listItem__item:last-child {
    margin: 0px;
  }
 .listItem__item--img {
    width: 22px;
    height: 22px;
    position: relative;
    z-index: 2;
  }
 .listItem__item.facebook::before {
    background: rgba(44, 103, 216);
  }
 .listItem__item.telegram::before {
    background: #0088cc;
  }
 .listItem__item.instagram::before {
    background: -moz-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: -webkit-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
  }
 .listItem__item.reddit::before {
    background: rgba(235, 85, 40, 1);
  }
 .listItem__item.discord::before {
    background: #5865f2;
  }
 .listItem__item.twitter::before {
    background: #1da1f2;
  }
 .listItem__item.linkedin::before {
    background: #0077b5;
  }
  .blockFooter__wrap--support {
    width: calc(50% - 150px);
    z-index: 2;
    padding: 0px 25px 0px 0px;
    @media screen and (max-width : 991px) {
      width : 100%;
      padding: 0px 10px;
    }
  }
  .blockFooter__wrap--info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index : 1;
    @media screen and (max-width : 991px) {
      bottom : unset;
      margin : 0 auto;
      top : -110px;
    }
  }
 .info__logo {
  width: 180px;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  }
 .logo__img {
    position: relative !important;
    width: 125px;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    @media screen and (max-width : 991px) {
      width: 80px;
    }
  }
  
 .blockFooter__wrap--disclaimer {
    width: calc(50% - 115px);
    @media screen and (max-width : 991px) {
      width : 100%;
      display : flex;
      flex-direction : column;
      align-items : center;
      max-width : 500px;
      margin : 0 auto;
      text-align : center;
    }
  }
 .blockFooter__wrap--disclaimer strong {
    font-family: SUISSE_BOLD;
    font-size: 2rem;
    line-height: calc(2rem * 1.3);
    text-transform: uppercase;
  }
 .blockFooter__wrap--disclaimer i {
    font-family: SUISSE_BOLD;
    font-size: 2rem;
    line-height: calc(2rem * 1.3);
  }
 .blockFooter__wrap--disclaimer p:not(:first-child) {
    margin: 10px 0px 0px 0px;
    font-size: 1.2rem;
    line-height: calc(1.2rem * 1.3);
    font-family: F_REGULAR;
    transition: 0.4s all;
  }
  
 .blockFooter__wrap--content {
  @media screen and (max-width : 991px) {
    width : 100%;
    display : flex;
    flex-direction : column;
    align-items : center;
    margin : 35px auto 0px auto;
  }
  display: -webkit-box;
  width: calc(50% - 150px);
  display: -ms-flexbox;
  display: flex;
  position : relative;
  z-index: 2;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  }
  
  
 .blockFooter__wrap--info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: center;
  }
  
 .content__contact {
    padding: 0px 25px;
    width: calc(45% - 20px);
  }
 .content__contact--form {
    margin: 25px 0px 0px 0px;
  }
 .form__getNews {
    width: 450px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    padding: 10px 10px 10px 20px;
    border-radius: 50px;
  }
 .getNews__input {
    width: calc(100% - 135px);
    height: 40px;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    font-family: FONT_MEDIUM;
    font-size: 1.6rem;
    line-height: calc(1.6rem * 1.3);
  }
 .getNews__input::-webkit-input-placeholder {
    color: #888;
  }
 .getNews__input::-moz-placeholder {
    color: #888;
  }
 .getNews__input:-ms-input-placeholder {
    color: #888;
  }
 .getNews__input::-ms-input-placeholder {
    color: #888;
  }
 .getNews__input::placeholder {
    color: #888;
  }
 .getNews__btn {
    width: 120px;
    height: 40px;
    border-radius: 20px;
    font-family: FONT_SEMIBOLD;
    cursor: pointer;
    font-size: 1.6rem;
    background: #d8293a;
    color: #fff;
    line-height: calc(1.6rem * 1.3);
    border: none;
  }
 .form__title {
    font-family: FONT_SEMIBOLD;
    text-transform: uppercase;
    margin: 0px 0px 20px 0px;
    font-size: 1.8rem;
    line-height: calc(1.8rem * 1.3);
  }
 .content__contact {
    margin: 40px 0px 0px 0px;
  }
 .content__contact--disclamer {
    margin: 45px 0px 0px 0px;
  }
 .disclamer__title {
    font-family: FONT_SEMIBOLD;
    text-transform: uppercase;
    margin: 0px 0px 20px 0px;
    font-size: 1.8rem;
    line-height: calc(1.8rem * 1.3);
  }
 .disclamer__content {
    font-family: FONT_MEDIUM;
    color: rgba(244, 183, 78);
    padding: 0px 75px 0px 0px;
    font-size: 1.6rem;
    line-height: calc(1.6rem * 1.3);
  }
 .content__listMenu {
  width: calc(80% - 20px);
  margin: 0px 0px 0px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  }
 .content__listMenu--item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: calc(50% - 20px);
  margin: 0px 20px;
  }
 .item__listLink {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
 .item__listLink--item {
    margin: 20px 0px 0px 0px;
    font-size: 1.4rem;
    line-height: calc(1.4rem * 1.3);
    font-family: F_REGULAR;
    -webkit-transition: 0.4s all;
    -o-transition: 0.4s all;
    transition: 0.4s all;
  }
 .item__listLink--item:hover {
    color: rgba(255, 255, 255, 0.6);
    -webkit-transition: 0.4s all;
    -o-transition: 0.4s all;
    transition: 0.4s all;
  }
 .item__title {
    font-family: SUISSE_BOLD;
    font-size: 2rem;
    line-height: calc(2rem * 1.3);
    text-transform: uppercase;
  }
  .blockCopyRight {
    width: 100%;
    background: #000;
    padding: 15px 0px;
    margin : 30px 0px 0px 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .blockCopyRight .blockCopyRight__wrap--text {
    text-align: center;
    color: #FFF;
    margin: 5px 0px 0px 0px;
    font-family: SUISSE_BOLD;
  }
  .blockCopyRight .blockCopyRight__wrap--text a {
    color: #000;
  }
  .blockCopyRight .blockCopyRight__wrap--text:first-child {
  margin: 0;
  }
 .support__text {
    color: #FFF;
    font-family: SUISSE_BOLD;
    margin : 10px 0px;
    font-size: 16px;
    line-height: calc(16px * 1.25);
  }
 .blockFooter__wrap--disclaimer {
    width: 100%;
  }
  
 .titleDisclaimer {
    font-family: SUISSE_BOLD !important;
    font-size: 20px !important;
    line-height: calc(20px * 1.25) !important;
  }
  
 .mailSupport {
    font-family: SUISSE_BOLD !important;
    font-size: 20px !important;
    line-height: calc(20px * 1.25) !important;
  }
  
 .itemSocial__img {
    width: 22px;
    height: 22px;
  }
  
 .listSocial {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
 .itemSocial {
    width: 40px;
    min-width : 40px;
    height: 40px;
    background: #FFF;
    border-radius: 50%;
    transition: 0.4s all;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px 0px 0px;
    cursor: pointer;
  }
 .itemSocial:hover {
    opacity: 0.7;
    transition: 0.4s all;
  }

 
`