import React, { useEffect, useState } from 'react'
import 'react-fancy-circular-carousel/FancyCarousel.css'

// import { ChainId } from '@pancakeswap/swap-sdk'
import dynamic from 'next/dynamic'
import { useChainId } from 'wagmi'

// import { BTCETF_SPOT } from '@/config/constants/tokens'

import ChartContainer from './ChartContainer'
import {
  BlockTokenomics,
  ContentWrap,
  InfoTokenomics,
  ItemTokenomics,
  LinkDirect,
  TextTitle,
  WrapChartTokenomics,
  WrapCircle,
  WrapLinkDirect,
  WrapTitle,
} from './style'
import CircleSVG from './CircleSVG'

const FancyCarousel = dynamic(() => import('react-fancy-circular-carousel'), { ssr: false })

const imagesToken = [
  '/images/tokenomics/eth.png',
  '/images/tokenomics/usdt.png',
  '/images/tokenomics/bnb.png',
  '/images/tokenomics/xrp.png',
  '/images/tokenomics/sol.png',
  '/images/tokenomics/usdc.png',
  '/images/tokenomics/ada.png',
  '/images/tokenomics/avax.png',
  '/images/tokenomics/doge.png',
  '/images/tokenomics/trx.png',
  '/images/tokenomics/zkSync.png',
  '/images/tokenomics/arb.png',
  '/images/tokenomics/op.png',
]

const Tokenomics = () => {
  const [isMobile, setIsMobile] = useState(false)

  const chainId = useChainId()

  const address = '0x9b4a5bc99331b9adef07f37a39a4c4b0adc0c6fc'

  useEffect(() => {
    const checkWindowSize = () => {
      setIsMobile(window.innerWidth < 767)
    }
    checkWindowSize()
    window.addEventListener('resize', checkWindowSize)
    return () => {
      window.removeEventListener('resize', checkWindowSize)
    }
  }, [])
  return (
    <BlockTokenomics id="tokenomics">
      <ContentWrap className="blockContainer">
        <TextTitle>Tokenomics</TextTitle>
        <WrapChartTokenomics>
          <WrapCircle>
            <ChartContainer />
            <CircleSVG />
          </WrapCircle>
          <InfoTokenomics>
            <ItemTokenomics>Total Supply: 10.000.000.0000 XGT</ItemTokenomics>
            <br />
            <ItemTokenomics>
              Burn (6 Phase) : 5.000.000.0000 <span>(50%)</span>
            </ItemTokenomics>
            <ItemTokenomics>
              Presale Fund : 1.000.000.0000 <span>(10%)</span>
            </ItemTokenomics>
            <ItemTokenomics>
              Liquidity Fund : 1.000.000.0000 <span>(10%)</span>
            </ItemTokenomics>
            <ItemTokenomics>
              Fund for Development of Technology Products and CarbonCredit Ecosystem - Games – NFT – Artificial
              Intelligence AI - Farming - Staking : 2.000.000.0000 <span>(20%)</span>
            </ItemTokenomics>
            <ItemTokenomics>
              Marketing, Airdrop : 500.000.0000 <span>(5%)</span>
            </ItemTokenomics>
            <ItemTokenomics>
              Founder and Dev : 500.000.0000 <span>(5%)</span>
            </ItemTokenomics>
            <br />
            <ItemTokenomics>
              Token Address
              <WrapLinkDirect>
                <LinkDirect href={`https://bscscan.com/address/${address}`} target="_blank">
                  BscScan
                </LinkDirect>
              </WrapLinkDirect>
            </ItemTokenomics>
          </InfoTokenomics>
        </WrapChartTokenomics>
      </ContentWrap>
    </BlockTokenomics>
  )
}
export default Tokenomics
