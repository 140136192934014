import Image from 'next/image'
import React from 'react'

import Link from 'next/link'
import { BlockFooter } from './style'

const FooterWeb = () => {
  return (
    <>
      <BlockFooter className="blockFooter" id="support">
        <div className="blockFooter__wrap blockContainer">
          <div className="blockFooter__wrap--info">
            <div className="info__logo">
              <Image className="logo__img" src="/logo.png" alt="" width="0" height="0" sizes="100vw" />
            </div>
          </div>
          <div className="blockFooter__wrap--support">
            <div className="blockFooter__wrap--disclaimer">
              <p className="titleDisclaimer">DISCLAIMER</p>
              <br />
              Nothing on this website constitutes financial advice, and it is always recommended to consult a qualified
              financial advisor before participating in any token or NFT purchases. If you have any questions please
              contact us at:
              <p className="mailSupport">info@xgroot.pro</p>
            </div>
          </div>
          <div className="blockFooter__wrap--content">
            <div className="content__listMenu">
              <div className="listSocial">
                <Link
                  href="https://www.youtube.com/channel/UC3exutGzuGNkvB5cy0t32zQ"
                  className="itemSocial"
                  target="_blank"
                >
                  <Image
                    src="/images/icYoutube.svg"
                    className="itemSocial__img"
                    alt=""
                    width={0}
                    height={0}
                    sizes="100vw"
                  />
                </Link>
                <Link href="https://twitter.com/xgrootnft" className="itemSocial" target="_blank">
                  <Image src="/images/icX.svg" className="itemSocial__img" alt="" width={0} height={0} sizes="100vw" />
                </Link>
                <Link href="https://t.me/Xgrootcommunity" className="itemSocial" target="_blank">
                  <Image
                    src="/images/icTele.svg"
                    className="itemSocial__img"
                    alt=""
                    width={0}
                    height={0}
                    sizes="100vw"
                  />
                </Link>
                <Link href="https://t.me/xgrootnft" className="itemSocial" target="_blank">
                  <Image
                    src="/images/icTele.svg"
                    className="itemSocial__img"
                    alt=""
                    width={0}
                    height={0}
                    sizes="100vw"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="blockCopyRight">
          <div className="blockCopyRight__wrap blockContainer">
            <div className="blockCopyRight__wrap--text">© 2024 XGroot. All Rights Reserved.</div>
          </div>
        </div>
      </BlockFooter>
    </>
  )
}
export default FooterWeb
