import FooterWeb from 'components/FooterWeb'
import Hero from './components/Hero'
import Trailer from './components/Trailer'
import { BlockDashboard, BlockDashboardWrap } from './style'
import Partner from './components/Partner'
import Airdrop from './components/Airdrop'
import News from './components/News'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Review from './components/Review'
import Tokenomics from './components/Tokenomics'
import Roadmap from './components/Roadmap'
import Presale from './components/Presale'
import BurnToken from './components/BurnToken'
import Donate from './components/Donate'

const Dashboard = () => {
  return (
    <BlockDashboard>
      <BlockDashboardWrap>
        <Hero />
        <Presale />
        <Airdrop />
        <BurnToken />
        <Trailer />
        <News />
        <Donate />
        <Roadmap />
        <Tokenomics />
        <Review />
        <Partner />
        <FooterWeb />
      </BlockDashboardWrap>
    </BlockDashboard>
  )
}

export default Dashboard
