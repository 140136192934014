import styled from "styled-components"

export const BlockDonate = styled.div`
    padding: 50px 0px;
    position : relative;
    z-index : 2;
    background  : #000;
    @media screen and (max-width: 1199px) {
        padding: 50px 0px;
    }
`

export const ContentWrap = styled.div``


export const WrapBackgroundDonate = styled.div`
    position : relative;
    width: 100%;
`
export const ImgBackground = styled.img`
    width : 100%;
    height: 600px;
    object-fit : cover;
    @media screen and (max-width: 1199px) {
        height: 100%;
    }
`

export const WrapButton = styled.div`
    position : absolute;
    bottom : 150px;
    left : 0px;
    right : 0px;
    margin : 0 auto;
    display  : flex;
    align-items : center;
    justify-content : center;
    button {
        background : transparent;
    }
    @media screen and (max-width: 1199px) {
        top  : 25px;
    }
`
export const ImgDonate = styled.img`
    width: 125px;
    height: 125px;
    object-fit : contain;
`
