import React from 'react'
import { Button } from '@pancakeswap/uikit'
import { BlockDonate, ContentWrap, ImgBackground, ImgDonate, WrapBackgroundDonate, WrapButton } from './style'

const Donate = () => {
  return (
    <BlockDonate id="donate">
      <ContentWrap>
        <WrapBackgroundDonate>
          <ImgBackground src="/images/bgDonate.png" />
          <WrapButton>
            <Button>
              <ImgDonate src="/images/btnDonate.png" />
            </Button>
          </WrapButton>
        </WrapBackgroundDonate>
      </ContentWrap>
    </BlockDonate>
  )
}
export default Donate
