import React from 'react'

import { Button } from '@pancakeswap/uikit'
import Link from 'next/link'
import hotNews from '@/public/news/hot.json'
import {
  BlockNews,
  ContentNews,
  ContentWrap,
  DescNews,
  ImgNews,
  ItemNews,
  ListNews,
  TextTitle,
  TitleNews,
  WrapViewMore,
} from './style'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const News = () => {
  return (
    <>
      <BlockNews id="news">
        <ContentWrap className="blockContainer">
          <TextTitle>News</TextTitle>
          <ListNews {...settings}>
            {hotNews.map((item) => {
              return (
                <ItemNews href={item.href} target="_blank">
                  <ImgNews src={item.imgSrc} alt="" />
                  <ContentNews>
                    <TitleNews>{item.title}</TitleNews>
                    <DescNews>{item.desc}</DescNews>
                  </ContentNews>
                </ItemNews>
              )
            })}
          </ListNews>
          <WrapViewMore>
            <Link href="/news">
              <Button>View More</Button>
            </Link>
          </WrapViewMore>
        </ContentWrap>
      </BlockNews>
    </>
  )
}
export default News
